import { Link } from "gatsby";
import React from "react";

import style from "./header.module.css";

const Header = ({ siteTitle }) => {
  return (
    <header className={style.header}>
      <div className={style.headerContainer}>
        <a
          href="/"
          className={style.logo}
          title="One Time Message Service"
        >
          <img
            style={{ marginBottom: 0 }}
            src={'/images/otm-alone-transparent.png'}
            alt="One Time Message Service"
            height="60px"
            width="60px"
          />
        </a>
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
    </header>
  )
}

export default Header;
