import { Link } from "gatsby";
import React from "react";

const { version } = require('../../package.json');

const Footer = (props: any) => {
  return (
    <footer>
      <div className="footerContainer">
        <div className="copyright">© {new Date().getFullYear()}</div>
        <div className="links">
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="links">
          <a href="https://vt.team" target="_blank">
            Built by vt.team
          </a>
          <span> - v{version}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
